exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */),
  "component---src-pages-subscribe-confirm-index-tsx": () => import("./../../../src/pages/subscribe/confirm/index.tsx" /* webpackChunkName: "component---src-pages-subscribe-confirm-index-tsx" */),
  "component---src-pages-subscribe-index-tsx": () => import("./../../../src/pages/subscribe/index.tsx" /* webpackChunkName: "component---src-pages-subscribe-index-tsx" */),
  "component---src-pages-subscribe-thanks-index-tsx": () => import("./../../../src/pages/subscribe/thanks/index.tsx" /* webpackChunkName: "component---src-pages-subscribe-thanks-index-tsx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2025-industry-changing-index-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/Users/y/trekhleb.github.io/src/posts/2025/industry-changing/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2025-industry-changing-index-mdx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/Project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */)
}

